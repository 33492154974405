angular.module("adminApp")
    .factory('editUserInfoModal', ["$uibModal", "$filter", "aerosAdminApi", function ($uibModal, $filter, aerosAdminApi) {

        function open(org, orgUser, isInfo, title, availableLicenses) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/admin/modals/editUserInfoModal.html",
                controller: "EditUserInfoModalCtrl",
                resolve: {
                    org: function () {
                        return org;
                    },
                    orgUser: function () {
                        return orgUser;
                    },
                    isInfo: function () {
                        return isInfo;
                    },
                    title: function () {
                        return title;
                    },
                    availableLicenses: function () {

                        return aerosAdminApi.getLicenseTerms().then(function (results) {
                            return decorateAvailableLicenses(availableLicenses,
                                results.data.licenseTerms);
                        });

                    }
                }
            });
            return modal.result;
        }

        function decorateAvailableLicenses(availableLicenses, licenseTypes) {

            var licenses = availableLicenses.map(function (item) {
                return angular.extend({}, item, {
                    type: $filter('translate')('Available Licenses')
                })
            });

            var licenseTypes = licenseTypes.map(function (item) {
                return angular.extend({}, item, {
                    type: $filter('translate')('Generate New License'),
                    label: item.desc,
                    id: -item.id
                })
            });

            return licenses.concat(licenseTypes);
        }

        return {
            open: open,
            decorateAvailableLicenses: decorateAvailableLicenses
        };
    }])
    .controller("EditUserInfoModalCtrl", ["$scope", "$q", "$filter", "$uibModalInstance", "Notification",
        "aerosAdminApi", "org", "orgUser", "isInfo", "title", "availableLicenses",
        function ($scope, $q, $filter, $uibModalInstance, Notification, aerosAdminApi, org, orgUser, isInfo, title,
                  availableLicenses) {

            angular.extend($scope, {
                generateLicense: generateLicense,
                selectLicenseListener: selectLicenseListener,
                cancel: cancel,
                addUser: addUser
            });

            (function init() {
                $scope.isSubmitted = false;
                $scope.org = org;
                $scope.title = title;
                $scope.isInfo = isInfo;
                $scope.licenseTypes = [];
                $scope.availableLicenses = availableLicenses;
                $scope.rolesToAssign = [
                    {id: "orgadmin", name: "Org Admin"},
                    {id: "supervisor", name: "Project Manager"}
                ];

                getTerms();
		

		$scope.$watch('userInfoModel', function() {
                    if (($scope.userInfoModel.newLicenseModel.key+'')[0] == '-') {
                        $scope.licenseCaption = '';
                    } else {
                        $scope.licenseCaption = $scope.userInfoModel.newLicenseModel.key;
                    }
                }, true);
                
                $scope.userInfoModel = {
                    firstName: "",
                    lastName: "",
                    roles: ["orgadmin"],
                    email: "",
                    newLicenseModel: {
                        numLicenses: 1,
                        licenseType: "",
                        key: ""
                    }
                };

            })();

            function getTerms() {
                aerosAdminApi.getLicenseTerms().success(function (data) {
                    var terms = data.licenseTerms;
                    _.each(terms, function (term) {
                        $scope.licenseTypes.push(term);
                    });
                });
            }

            function generateLicense() {
                $scope.userInfoModel.newLicenseModel.term = $scope.userInfoModel.newLicenseModel.licenseType.name;

                return aerosAdminApi.generateLicense($scope.userInfoModel.newLicenseModel)
                    .then(successHandler, errorHandler);

                function successHandler(res) {
                    $scope.availableLicenses.push(angular.extend(res.data.licenses[0], {
                        type: $filter('translate')('Available Licenses'),
                        label: res.data.licenses[0].id + " (" + $scope.userInfoModel.newLicenseModel.licenseType.desc
                            + ")"
                    }));
                    $scope.userInfoModel.newLicenseModel.key = res.data.licenses[0].id;
                    //$scope.isSubmitted = true;
                    return aerosAdminApi.addLicenseToOrganization($scope.org.id, $scope.userInfoModel.newLicenseModel.key)
                        .then(function () {
                            Notification.success("License added to organization. " + $scope.org.name);
                        }, function (err) {
                            Notification.error("License not added. " + err.data.message);
                            return $q.reject(err);
                        });
                }

                function errorHandler(err) {
                        Notification.error("License not generated. " + err.data);
                        $scope.isSubmitted = false;
                        return $q.reject(err);
                }
            }

            function selectLicenseListener() {

                $scope.userInfoModel.newLicenseModel.licenseType = $scope.licenseTypes.find(function (item) {
                    return item.id === -$scope.userInfoModel.newLicenseModel.key;
                });

                if ($scope.userInfoModel.newLicenseModel.licenseType) {
                    return generateLicense();
                }

                return $q.resolve();
            }

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function addUser() {

                selectLicenseListener().then(() => {
                    $uibModalInstance.close($scope.userInfoModel)
                });

            }
        }]);
